import React from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Table, Label, 
    ModalBody, ModalFooter, Row, InputGroup, InputGroupAddon, InputGroupText, Alert } from 'reactstrap';
import Select from 'react-select';
import './StyleExamCreate.css';


export const CreateExam = (props) => {

    const {
        formValues, examId, methodExam, toggle, handleInputChange, handleSelectValues, handleChangePrice, validaciones, loading,
        sections, changeSelect, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
        cost, validationSectionArea, id_section,
        methodAction, isSimple = false, isImagenology = false,
        infoEdit = {}, examEditId, isImagenologyy, print_new_page,
         //*CONFIGURATION LABORATORY EXTERNAL
         dataLaboratory,handleChangePriceIni
    } = props;

    const {
        code: validationCode,
        alias: validationAlias,
        name: validationName,
        abbreviation: validationAbbreviation,
        // individual_sale: validationIndividual,
        id_section: validationSection,
        id_specimen_origin: validationSpecimenOrigen
    } = validaciones;


    const {
        code
    } = formValues;

    const txtsection = localStorage.getItem('TextAS');

    return (
        <Form onSubmit={methodAction} >
            <ModalBody>
                <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0' >
                    <Row>
                        <Col sm="6" md="4" lg="3">
                        <FormGroup>
                        <Label className='f-w-600 f-12 badge badge-light-secondary'>Clave <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.code}
                            type="text"
                            name="code"
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                            invalid={validationCode}
                        />
                        <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                        </Col>
                        <Col sm="6" md="4" lg="3">
                        <FormGroup>
                            <Label className='f-w-600 f-12 badge badge-light-secondary'>Alias <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary"
                                value={formValues.alias}
                                //  placeholder="Ingresa el alias"
                                type="text"
                                name="alias"
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={validationAlias}
                            />
                            <FormFeedback>El alias debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                        </Col>
                        <Col sm="6" md="4" lg="3">
                        <FormGroup>
                        <Label className='f-w-600 f-12 badge badge-light-secondary'>Abreviatura <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.abbreviation}
                            //placeholder="Ingresa la abreviatura"
                            type="text"
                            name="abbreviation"
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                            invalid={validationAbbreviation}
                        />
                        <FormFeedback>La abreviatura debe tener 2 o más caracteres</FormFeedback>
                    </FormGroup>
                        </Col>
                        <Col sm="6" md="4" lg="3">
                        <FormGroup>
                            <Label className='f-w-600 f-12 badge badge-light-secondary'>Tiempo de entrega<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary"
                                value={formValues.delivery_time}
                                type="text"
                                name="delivery_time"
                                required={false}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                            <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <FormGroup>
                            <Label className='f-w-600 f-12 badge badge-light-secondary'>Nombre del exámen<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary"
                                value={formValues.name}
                                // placeholder="Ingresa el nombre del exámen"
                                type="text"
                                name="name"
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={validationName}
                            />
                            <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <FormGroup >
                            <Label className='f-w-600 f-12 badge badge-light-secondary'>{txtsection} <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary"
                                value={formValues.id_section}
                                type="select"
                                name="id_section"
                                required={true}
                                onChange={changeSelect}
                                invalid={validationSection}
                            >
                                <option value="0">Selecciona una opción</option>
                                {
                                    sections.length > 0 && sections.map((s, key) => {
                                        return <option key={key} value={s.id_section}>
                                            {s.name}
                                        </option>
                                    })
                                }
                            </Input>
                            <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                        </FormGroup>
                        </Col>
                        {/* <Col sm="6" md="4" lg="4">
                            <FormGroup>
                            <Label className='labelInfoAnalyte'>Subsección</Label>
                            <Input
                                className="form-control form-control-sm input-air-primary"
                                value={formValues.id_subsection}
                                type="select"
                                name="id_subsection"
                                required={true}
                                onChange={handleInputChange}
                            >
                                <option value="0">Selecciona una opción</option>
                                {
                                    subSections.length > 0 && subSections.map((ss, key) => {
                                        return <option key={key} value={ss.id_subsection}>
                                            {ss.name}
                                        </option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col sm="6" md="4" lg="3">
                        <FormGroup>
                            <Label className='f-w-600 f-12 badge badge-light-secondary'>Tipo de muestra <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Select
                                value={formValues.id_specimen}
                                classNamePrefix="select"
                                name="id_specimen"
                                placeholder={`Selección de especimen`}
                                isClearable={false}
                                noOptionsMessage={() => "Sin opciones"}
                                options={specimens}
                                onChange={(e) => handleSelectValues(e, "id_specimen")}
                            />
                            <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                        </FormGroup>
                        </Col>
                        <Col sm="6" md="4" lg="3">
                        <FormGroup>
                            <Label className='f-w-600 f-12 badge badge-light-secondary'>Procedencia de la muestra <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            {/* specimenProvenances */}
                            <Select
                                value={formValues.id_specimen_provenance}
                                classNamePrefix="select"
                                name="id_specimen_provenance"
                                placeholder={`Seleciona la procedencia`}
                                isClearable={false}
                                noOptionsMessage={() => "Sin opciones"}
                                options={specimenProvenances}
                                onChange={(e) => handleSelectValues(e, "id_specimen_provenance")}
                            />
                            <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                        </FormGroup>
                        </Col>

                        <Col sm="6" md="4" lg="6">
                        <FormGroup>
                            <Label className='f-w-600 f-12 badge badge-light-secondary'>Medio(s) de transporte <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Select
                                value={formValues.list_transports}
                                isMulti
                                classNamePrefix="select"
                                name="list_transports"
                                onChange={(e) => handleSelectValues(e, "list_transports")}
                                // value={valuePeriod}
                                placeholder={`Selección de medio de transporte`}
                                isClearable={false}
                                noOptionsMessage={() => "Sin opciones"}
                                options={transports}

                            />
                            <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                        </FormGroup>
                        </Col>
                       
                    </Row>
                    <Row>
                      
                    </Row>
                    <br />

                    <Row>
                        <Col xs="6" sm="6" md="3" lg="3">
                        <FormGroup>
                            <Label >Venta individual</Label>
                            <br />
                            <Label className="switch">
                                <Input type="checkbox" checked={formValues.individual_sale} name="individual_sale" onChange={handleInputChange} />
                                <span className="slider round"></span>
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col xs="6" sm="6" md="3" lg="3">
                        <FormGroup >
                            <Label>¿Es referenciado?</Label>
                            <br />
                            <Label className="switch">
                                <Input type="checkbox" checked={formValues.is_referenced} name="is_referenced" onChange={handleInputChange} />
                                <span className="slider round"></span>
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col xs="6"  sm="6" md="3" lg="3">
                        <FormGroup >
                            <Label>¿Es critico?</Label>
                            <br />
                            <Label className="switch">
                                <Input type="checkbox" checked={formValues.is_critic} name="is_critic" onChange={handleInputChange} />
                                <span className="slider round"></span>
                            </Label>
                        </FormGroup>
                        </Col>
                        <Col xs="12"  sm="6" md="3" lg="3">
                        <FormGroup >
                            <Label>¿Impresión en una hoja diferente?</Label>
                            <br />
                            <Label className="switch">
                                <Input type="checkbox" checked={formValues.print_new_page} name="print_new_page" onChange={handleInputChange} />
                                <span className="slider round"></span>
                            </Label>
                        </FormGroup>
                        </Col>

                    </Row>



                </Col>
                
              
                <Row>
                  
                   
                    
                   
                   
                </Row> <br />
                <Row style={{ textAlign: "-webkit-center" }}>
                  
                    <Col sm="3">
                        {
                            examId > 0 ? "" :
                               
                                    !formValues.is_referenced && <Row>
                                        <FormGroup className="mb-2 col-11 mb-3">
                                            <Label>Costo de producción <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <InputGroup className="">
                                                <Input type="number" min={0} className="form-control" name="cost" value={cost} onChange={handleInputChange} />
                                                <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Row>
                               
                        }
                    </Col>
                   
                   
                 
                   
                </Row>
                {
                    examId > 0 ?
                        "" :
                        <>
                           
                            <Row>
                                {
                                         !formValues.is_referenced && <Col md="6">
                                         <Label>Precio inicial</Label>
                                         <Table className="table table-bordered table-sm">
                                             <tbody>
                                                 {
                                                     commercialLines.length >= 1 && commercialLines.map((data, key) => {
                                                         return (
                                                             <tr key={key}>
                                                                 <td className='bg-secondary' >{data.short_name}</td>
                                                                 <td className='bg-secondary' >
                                                                     <InputGroup className="">
                                                                         <Input
                                                                             type="number"
                                                                             name="price_commercial_line"
                                                                             onChange={handleChangePrice}
                                                                             className="form-control"
                                                                             data-id={key} step="any" placeholder="Precio inicial" />
                                                                         <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                                                     </InputGroup>
     
                                                                 </td>
                                                             </tr>
                                                         )
                                                     })
                                                 }
                                             </tbody>
                                         </Table>
                                     </Col>
                                }
                         
                            </Row>
                        </>
                }
                <Input type='hidden' value={isSimple} name="is_simple" />
                <Input type='hidden' value={isImagenology} name="isImagenology" />

                <Row>
                    {
                        formValues.is_referenced &&
                        <Col sm="12">
                            <label className='txt-secondary f-14'>Asignar Precios a Laboratorios Externos</label>
                             <div  >
                            <Table className='b-r-5 table table-sm'>
                            <thead className='b-r-5'>
                              <tr style={{ backgroundColor: "#46AFE5" }}>
                                  <th className='labeltheadInvoice'>Laboratorio</th>
                                  <th className='labeltheadInvoice'>Precio</th>
                                  <th className='labeltheadInvoice'>Costo</th>
                                  <th className='labeltheadInvoice'>Default</th>
                              </tr>
                          </thead>
                          <tbody>
                            {
                                dataLaboratory?.map((_find,key) => {
                                    return(
                                        <tr>
                                            <td>{_find.external_laboratory ?? "-----"}</td>
                                            <td> 
                                                <Col sm="8" className='p-l-0 p-r-0' >
                                                <InputGroup className="">
                                                <Input  type="number" min={0}  step="any" className="form-control"   name="amount" value={_find.price}   onChange={(e) => handleChangePriceIni(e, _find.id_external_laboratory)} /> 
                                                <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                                </InputGroup>        
                                                </Col>                              
                                            </td>
                                            <td>
                                                <Col sm="8" className='p-l-0 p-r-0' >
                                                <InputGroup className="">
                                                <Input   type="number" min={0}  step="any" className="form-control"   name="cost" value={_find.cost}   onChange={(e) => handleChangePriceIni(e, _find.id_external_laboratory)}  /> 
                                                <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                                </InputGroup>    
                                                </Col>
                                            </td>
                                            <td style={{textAlign :"center"}} >
												<Input type='radio' name="default_lab" checked={_find.default_lab} onChange={(e) => handleChangePriceIni(e,_find.id_external_laboratory )}/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                          </tbody>
                            </Table>
                        </div>
                        </Col>
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                {
                    isSimple || isImagenology ?
                        <Button
                            outline color="primary"
                            type="submit"
                            className={`${(examId > 0 && examEditId === undefined) ? "d-none " : ""} ${loading ? "disabled progress-bar-animated progress-bar-striped" : ""}`}
                            //disabled={!validationSection}
                        >
                            {examEditId === undefined ? "Guardar" : "Actualizar"}
                        </Button>
                        : <Button
                            outline color="primary"
                            type="submit"
                            className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                            //disabled={!validationSection}
                        >
                            Guardar
                        </Button>
                }
                {
                    !isSimple &&
                    <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
                }
            </ModalFooter>
        </Form >
    )
}
