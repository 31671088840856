import { sendRequest } from '../../hooks/requests/useRequest'

export async function getPayMethod(_id_WorkOrder,_username,_password,_id_PayMethod,_id_Branch,_amount,_id_workOrder_pay)
{

    let _body = {
        id_work_order: _id_WorkOrder,
        user_autorize: _username,
        password_autorize: _password,
        id_pay_method: _id_PayMethod,
        id_branch:_id_Branch,
        amount:_amount,
        id_work_order_pay: _id_workOrder_pay
    };

    let data = JSON.stringify(_body);

    let requestOptions = {
        method: 'PUT',
        body: data

    };

    const response = await sendRequest(requestOptions, "WorkOrder/UpdatePayMethod");

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }   
}


export async function UpdateCortesyPatient(_id_WorkOrder,_username,_password,_id_PayMethod,_id_Branch,_amount,_id_workOrder_pay)
{

    let _body = {
        id_work_order: _id_WorkOrder,
    };

    let data = JSON.stringify(_body);

    let requestOptions = {
        method: 'PUT',
        body: data

    };

    const response = await sendRequest(requestOptions, "WorkOrder/UpdateCourtesyPatient");

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }   
}