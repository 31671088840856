import React, { Fragment, useState } from 'react'
import { Alert, Button, Card, CardBody, Col, Container, FormGroup, Label, Row, Table, Badge,Input,CardHeader } from 'reactstrap'
import {useParams } from "react-router-dom";
import { useCashGlobal } from '../../hooks/cash/useCashGlobal'
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import './styleCash.css';
import { ToolTips } from '../../components/tooltips/ToolTips';
import { ModalPreviewGlobalCash } from './ModalPreviewGlobalCash';
import { ModalPreviewGlobalBranch } from './ModalPreviewGlobalBranch';
import { ModalPreviewGlobalSearch } from './ModalPreviewGlobalSearch';
import { useCash} from '../../hooks/cash/useCash'
import { ModalReportCash } from './ModalReportCash';
import Select from 'react-select';




export const IndexCashFlowGlobal = () => {

    //localStorage
    let branchs = localStorage.getItem('branchName');
    let user = localStorage.getItem('nameUser');

    const {
        //filter type cash flow global
         loadingData,
        //date
        date1, date2, handlePickerValues, getInformationGlobal, dataGlobal, TotalAmount, TotalAmountCapture, formatter,
        //Amount Total Branch
        branch, amountTotal,
        //modal Preview Global
        typeModal, modal, toggle, 
        //cardInfo
        cardInfo, cardInfoKey,
        //modal Preview
        toggleB, typeModalB, modalB,
        //modal Search
        toggleS, typeModalS, modalS,
        dataHistoric,printTicket,
        // ? SALDOS
        loadingC,creditTotal,number_format,navigateDetailPatient,amountTotal2

    } = useCashGlobal();

    const {
          //*CONFIGURATION CASH
          handleInputChangeCash,loading, 


          //*FINAL REPORT
          dataReport, close, modalProps,totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1,dataPaymets, dataCancell, 
          dataDiscount, dataPatient,mDateRange1, mDateRange2, getReport, mSelectBranch, handleSelectValuesCash,
          viewFormFilters, printCashClosing,branches, totalPaymets,exportToExcel,
  
          //*FINAL REPORT TYPE COMPANY
          type_company,handleClean,dataMDiscount,dataUser,ids_users,modalFullRef,handleReport
  

    } = useCash();

        const { Admin } = useParams();

        // ? CONFIGURATIOM REPORT
        const [isCredit, setIsCredit] = useState(false);
        const [expandedRows, setExpandedRows] = useState({}); 
        const [isHovered, setIsHovered] = useState(false);
        const [isHovered2, setIsHovered2] = useState(false);
        const [isHovered3, setIsHovered3] = useState(false);


        const handleView = () => {
            setIsCredit(true)
        }

        const handleClosed = () => {
            setIsCredit(false)
        }


        const toggleRow = (index) => {
            setExpandedRows((prev) => ({
                ...prev,
                [index]: !prev[index], 
            }));
        };

        const totalRow = (
            <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
            <td></td>
            <td style={{textAlign:"end",fontSize:"10px", color:"#fff"}} ><label  >Totales:</label> </td>
            <td  style={{textAlign:"center",fontSize:"10px", color:"#fff"}}  ><label > ${formatter.format(TotalAmount)}</label> </td>
            <td  style={{textAlign:"center",fontSize:"10px", color:"#fff"}} ><label  > ${formatter.format(TotalAmountCapture)}</label> </td>
            <td></td>


           
        </tr> 
        );

        const calculateTotals = (branchName) => {
            const filteredData = dataGlobal.filter(item => item.branch === branchName);
            const totalAmount = filteredData.reduce((acc, item) => acc + parseFloat(item.amount.replace(/[^0-9.-]+/g, "")), 0);
            const totalCaptureAmount = filteredData.reduce((acc, item) => acc + parseFloat(item.captureAmount.replace(/[^0-9.-]+/g, "")), 0);
            return { totalAmount, totalCaptureAmount };
        };

       const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };


    const divStyle = {
        backgroundColor: isHovered ? "#1e90ff" : "#58b1df",
        borderColor: "#46afe5",
        color: "#fff",
        textAlign: "center",
        cursor: "pointer",
        padding: "8px 16px",  
      };


    const handleMouseEnter2 = () => {
        setIsHovered2(true);
    };
    
    const handleMouseLeave2 = () => {
        setIsHovered2(false);
    };
    
    
    const divStyle2 = {
            backgroundColor: isHovered2 ? "#6c757dde" : "#6c757d78",
            borderColor: "#000",
            color: "#fff",
            textAlign: "center",
            cursor: "pointer",
            padding: "8px 16px",  
    };


    const handleMouseEnter3 = () => {
        setIsHovered3(true);
    };
        
    const handleMouseLeave3 = () => {
        setIsHovered3(false);
    };
            
    const divStyle3 = {
            backgroundColor: isHovered3 ? "#dc3545" : "#dc3545cc",
            borderColor: "#000",
            color: "#fff",
            textAlign: "center",
            cursor: "pointer",
            padding: "8px 16px",  
    };
        

    const totalRow2 = (
        <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
        <td ></td>
        <td></td>
        <td style={{textAlign:"end"}} colSpan={2} className='labeltheadInvoice'><label className='f-12' >Total Pendiente:</label> </td>
        <td colSpan={2} style={{textAlign:"left"}}  className='labeltheadInvoice'><label className='f-12' > ${number_format(amountTotal2, 2)}</label> </td>
       
    </tr> 
);

console.log(loadingC);

    

 

    return (
        <Fragment>
            <Container fluid={true} >
                <Row className="mt-2 mb-3">
                    <Col sm="9" md="9" lg="9" className='p-l-0 p-r-0' > 
                    {!isCredit &&  <Card className='shadow'>
                            <CardBody className='pl-2 pr-2' >
                                <Col sm="12">
                                    <Row>
                                        <Col sm="10">
                                            <label className='f-16 txt-secondary' >Detallado Global de cortes</label>
                                        </Col>
                                       
                                    </Row>
                                </Col>
                                <hr />
                                <div className="table-responsive">
                                <Table className='b-r-5 table table-sm'>
                                    <thead className='b-r-5'>
                                        <tr style={{ backgroundColor: "#f3f3f3", borderBottom: "#e3ebf3", borderTop: "#e3ebf3" }}>
                                            <th></th>
                                            <th style={{ textAlign: "center" }}>Sucursal</th>
                                            <th style={{ textAlign: "center" }}>Monto Punto de Venta</th>
                                            <th style={{ textAlign: "center" }}>Monto Recibido</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {
                                        branch.length === 0 ?
                                            <tbody>
                                                <tr>
                                                    <td colSpan="5" style={{ textAlign: 'center' }}>
                                                        <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} style={{ width: '120px' }} />
                                                        <br /><br />
                                                        <p className='f-12 text-primary'>
                                                            <b>Realice el filtrado por fecha para poder visualizar los Cortes Globales.</b>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            :
                                            <tbody>
                                                {
                                                loadingC && <tr>
                                                     <td colSpan={5} className='bg-info text-center f-16'>
                                                         <i className="fa fa-spin fa-spinner"></i> Cargando...
                                                     </td>
                                                 </tr>
                                                }
                                                {
                                                  !loadingC &&  branch.map((a, b) => {
                                                        console.log("🚀 ~ branch.map ~ a:", a)
                                                        const amount = amountTotal[a]?.Amount || 0;
                                                        const amountCapture = amountTotal[a]?.AmountCapture || 0;

                                                        const hasSections = cardInfo[a]?.secciones || [];
                                                        const isEqual = hasSections.length > 0 && hasSections.every(section => section.isEqual);

                                                        return (
                                                            <React.Fragment key={b}>
                                                                <tr>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center" }} className={isEqual ? 'type-success' : 'type-cancell'}>
                                                                        <span onClick={() => toggleRow(b)} style={{ cursor: 'pointer' }}>
                                                                            {expandedRows[b]
                                                                                ? <i className=" txt-secondary f-18 fa fa-plus-square"></i>
                                                                                : <i className="txt-secondary f-18 fa fa-plus-square-o"></i>}
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center" }} className={isEqual ? 'type-success' : 'type-cancell'}>{a}</td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center" }} className={isEqual ? 'type-success' : 'type-cancell'}>${amount}</td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center" }} className={isEqual ? 'type-success' : 'type-cancell'}>${amountCapture}</td>
                                                                    <td style={{ verticalAlign: "middle", textAlign: "center" }} className={isEqual ? 'type-success' : 'type-cancell'}>
                                                                        {isEqual ? <i className='fa fa-check txt-success f-20'></i> : <i className='fa fa-times text-danger f-20'></i>}
                                                                    </td>
                                                                </tr>
                                                                {expandedRows[b] && (
                                                                    <tr>
                                                                        <td colSpan="5">
                                                                            <div className="table-responsive">
                                                                                <Table className="">
                                                                                    <thead className="theadInvoice">
                                                                                        <tr>
                                                                                            <th className='labelThead'>Encargado</th>
                                                                                            <th className='labelThead'>Fecha y Hora</th>
                                                                                            <th className='labelThead'>Tipo</th>
                                                                                            <th className='labelThead'>Monto Real</th>
                                                                                            <th className='labelThead'>Monto Capturado</th>
                                                                                            <th className='labelThead'>Observaciones</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                        {
                                                                            dataGlobal
                                                                                .filter(item => item.branch === a)
                                                                                .map((_data, _key) => {
                                                                                    const _valid_Amount = parseFloat(_data.amount.replace(/[^0-9.-]+/g, ""));
                                                                                    const _valid_capture_Amount = parseFloat(_data.captureAmount.replace(/[^0-9.-]+/g, ""));
                                                                                    const _valid_amounts = _valid_Amount === _valid_capture_Amount;

                                                                                    return (
                                                                                        <tr key={_key}>
                                                                                            <td className={_valid_amounts ? '' : 'type-cancell'} >{_data.userCapture}</td>
                                                                                            <td>{_data.fechCashFlow}</td>
                                                                                            <td >{_data.pay_method}</td>
                                                                                            <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_data.amount}</td>
                                                                                            <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_data.captureAmount}</td>
                                                                                            <td >{_data.comment}</td>
                                                                                           
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                        }
                                                                    {(() => {
                                                                        const { totalAmount, totalCaptureAmount } = calculateTotals(a);
                                                                        const totalValid = totalAmount === totalCaptureAmount; 
                                                                        const difference = totalAmount - totalCaptureAmount;
                                                                        const differenceClass = difference === 0 ? 'type-success-2' : 'type-cancell-2'; 

                                                                        return (
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td style={{ textAlign:"end", fontSize: "10px", color: "#fff", backgroundColor: 'rgb(1 12 18)' }}>
                                                                                    <label>Totales:</label>
                                                                                </td>
                                                                                <td className={totalValid ? 'type-success-2' : 'type-cancell-2'} style={{ fontSize: "10px", color: "#fff" }}>
                                                                                    <label>${totalAmount.toFixed(2)}</label>
                                                                                </td>
                                                                                <td className={totalValid ? 'type-success-2' : 'type-cancell-2'} style={{ fontSize: "10px", color: "#fff" }}>
                                                                                    <label>${totalCaptureAmount.toFixed(2)}</label>
                                                                                </td>
                                                                                <td className={differenceClass} style={{ fontSize: "10px", color: "#fff" }}>
                                                                                    <label>Diferencia: ${difference.toFixed(2)}</label>
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                        );
                                                                    })()}
                                                                    </tbody>
                                                                                </Table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                    })
                                                }
                                                  {totalRow}
                                            </tbody>
                                    }
                                </Table>
                            </div>
                            </CardBody>
                        </Card>}
                       
                       { isCredit && <Card className='shadow'>
                            <CardBody className='pl-2 pr-2'>
                            <Col sm="12">
                                    <Row>
                                        <Col sm="10">
                                            <label className='f-16 txt-secondary'>Saldos Pendientes</label>
                                        </Col>
                                       
                                    </Row>
                                </Col>
                                <div  className= 'table-wrapper-saldos'>
                        <Table className='b-r-5 table table-xs'>
                          <thead className='b-r-5'>
                              <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
                                  <th style={{textAlign:"center"}} >Solicitud(NIM)</th>
                                  <th style={{textAlign:"center"}}>Sucursal</th>
                                  <th style={{textAlign:"center"}}>Responsable</th>
                                  <th style={{textAlign:"centtable-wrapper-saldoser"}}>Fecha</th>
                                  <th style={{textAlign:"center"}}>Monto</th>
                                  <th style={{textAlign:"center"}}>Estatus</th>
                              </tr>
                          </thead>
                          <tbody>
                                {
                                    loadingC && <tr>
                                        <td colSpan={9} className='bg-info text-center f-16'>
                                            <i className="fa fa-spin fa-spinner"></i> Cargando...
                                        </td>
                                    </tr>
                                }
                              {!loadingC &&creditTotal?.map((_find, _key) => {
                                
                                  return (
                                      <tr key={_key}>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}> <b onClick={() => navigateDetailPatient(_find?.id_workOrder)} className='txt-warning pointer'>{_find?.nim ?? "---"}</b> </td>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{_find?.branch ?? "----"}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{_find?.userResponsable ?? "----"}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{_find?.fech ?? "----"}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}> <b className='txt-danger' >${number_format(_find?.amount_deposit ?? 0, 2)}</b>  </td>  
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}> <label className={`f-w-600 badge badge-danger`}>Pendiente</label> </td>                              
                                      </tr>
                                  );
                              })}
                              {totalRow2}
                          </tbody>
                      </Table>
                            </div>
                                
                            </CardBody>

                        </Card> }
                    </Col>
                    <Col sm="3" className='p-r-0' >
                    <Card className='shadow' > 
                    <CardHeader className='p-3  bg-warning'>
                          <i className='fa fa-filter fIconFilter f-20 '></i>
                          <label className='f-14'>Filtro de Acciones</label>
                    </CardHeader>
                    <CardBody className='p-3'>
                        <Row className='pb-4'>
                        <Col sm="12" md="12" lg="12" className='pt-2' >
                            <label className='f-w-500 f-12'>Fecha de incio</label>
                            <Datetime
                                inputProps={{
                                    placeholder: 'DD-MM-YYYY',
                                    name: 'date1',
                                    autoComplete: "off",
                                    className: "form-control form-control-sm input-air-primary"
                                }}
                                timeFormat={false}
                                value={date1}
                                locale="es"
                                closeOnSelect={true}
                                onChange={(e) => handlePickerValues(e, "date1")}
                                dateFormat="DD-MM-YYYY"
                            />
                        </Col>   
                        <Col sm="12" md="12" lg="12" className='pt-2' >
                            <label className='f-w-500 f-12'>Fecha fin</label>
                            <Datetime
                                inputProps={{
                                    placeholder: 'DD-MM-YYYY',
                                    name: 'date2',
                                    autoComplete: "off",
                                    className: "form-control form-control-sm input-air-primary"
                                }}
                                timeFormat={false}
                                value={date2}
                                locale="es"
                                closeOnSelect={true}
                                onChange={(e) => handlePickerValues(e, "date2")}
                                dateFormat="DD-MM-YYYY"
                            />
                        </Col>  
                        <Col sm="12" className='pt-2' >
                        <div className='pointer' onClick={() => getInformationGlobal()} style={divStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                            Busqueda
                        </div>
                        </Col>
                        <Col sm="12" className='pt-2' >
                        {
                            Admin ===  undefined ? "" :
                        <div className='pointer' ref={modalFullRef} onClick={() => handleReport()} style={divStyle2} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2} >
                            Reporte
                        </div>
                        }
                        </Col> 
                        <Col sm="12" className='pt-2'>
                        <div className='pointer' onClick={() => (isCredit ?  handleClosed() :  handleView()) } style={divStyle3} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3} >
                          { isCredit ? "Ocultar Reporte" : "Saldos Pendientes" }  
                        </div>
                        </Col>
                        </Row>
                    </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            <ModalPreviewGlobalCash
                {
                ...{ typeModal, modal, toggle, loadingData, dataGlobal, formatter,printTicket }
                }

            />

            <ModalPreviewGlobalBranch
                {
                ...{ toggleB, typeModalB, modalB, cardInfo, cardInfoKey, loadingData }
                }

            />

            <ModalPreviewGlobalSearch
                {
                ...{ toggleS, typeModalS, modalS, dataHistoric, formatter }
                }

            />
              <ModalReportCash
                {...modalProps}
                close={close}
                data={dataReport}
                branchs={branchs}
                totalPageCount1={totalPageCount1}
                currentPage1={currentPage1}
                nextPage1={nextPage1}
                previousPage1={previousPage1}
                goToPage1={goToPage1}
                dataPaymets={dataPaymets}
                dataCancell={dataCancell}
                dataDiscount={dataDiscount}
                dataPatient={dataPatient}
                mDateRange1={mDateRange1}
                mDateRange2={mDateRange2}
                handleInputChangeCash={handleInputChangeCash}
                getReport={getReport}
                loading={loading}
                mSelectBranch={mSelectBranch}
                handleSelectValuesCash={handleSelectValuesCash}
                branches={branches}
                formatter={formatter}
                viewFormFilters={viewFormFilters}
                printCashClosing={printCashClosing}
                totalPaymets={totalPaymets}
                exportToExcel={exportToExcel}
                dataMDiscount={dataMDiscount}
                handleClean={handleClean}
                dataUser={dataUser}
                ids_users={ids_users}
                type_company={type_company}
            >
            </ModalReportCash>

          
        </Fragment>


    )
}


