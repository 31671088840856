import React, { Fragment } from 'react'
import {Button, Modal, ModalBody, FormFeedback,Col, Form, Input, CardBody, Media } from 'reactstrap';
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';






export const ModalAutorizationInvoices = (props) => {

  const {
    modalAutorization,toggleModalAutorization,saveInvoices, handleSelecUser,handleSelectPassword,validatePassword,loadingServices,username


  } = props;




  return (
    <Modal isOpen={modalAutorization} toggle={toggleModalAutorization} backdrop='static' keyboard={false} centered={true}>
        <Form className="form theme-form" role="form">
            <ModalBody>   
            <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                
                    <>
                     <Media><Media className="img-100 img-fluid m-r-20 rounded-circle pt-3" src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                            <Media body>
                                <span className="d-block"><span><i className="icofont icofont-user"> </i><span className="px-2">{"Usuario"}
                                <Input
                                type="text"
                                name="user_method"
                                value={username}
                                required={true}
                                autoComplete="nope"
                                onChange={(e) => handleSelecUser(e.target.value)}
                                className="form-control form-control-sm input-air-primary"
                                disabled={loadingServices ? true : false}
                            />
                            <FormFeedback>Escribe el usuario</FormFeedback>
                               </span></span></span><span className="d-block"><span><i className="icofont icofont-key"></i><span className="px-2">{"Contraseña"}
                               <Input
                                type="password"
                                name="user_password_method"
                                required={true}
                                autoComplete="nope"
                                onChange={(e) => handleSelectPassword(e.target.value)}
                                disabled={loadingServices ? true : false}



                                className="form-control form-control-sm input-air-primary"
                            />
                            <FormFeedback>Escribe la contraseña</FormFeedback></span></span></span>
                            </Media>
                    </Media>
                    <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                        <div className="btngroup">
                            <Button disabled={loadingServices ? true : false}  color="danger text-center" type="button" onClick={toggleModalAutorization}>{"Cancelar"}</Button>
                            <Button disabled={loadingServices ? true : false} color="secondary text-center" type="button" onClick={validatePassword}  >{"Autorizar"}</Button>  
                        </div> 
                    </Col>
                    </>
                   
            </CardBody>   
            </ModalBody>
        </Form>
    </Modal>
)
}
