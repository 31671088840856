import React from 'react'
import {Button, Modal, ModalBody, Row,Col, Form, ModalHeader, CardBody, Media ,ModalFooter,Input} from 'reactstrap';
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styleM.css';


export const ModalCourtesyy = (props) => {

    const {ModalCourtesy,toggleCourtesy,user_method, user_password_method,handleInputChangeMethod,validatePasswordCortesy,aunthentication,loadingUpdateMethod} = props;
    
    return (
        <Modal isOpen={ModalCourtesy} toggle={toggleCourtesy} color='danger' backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggleCourtesy} className="bg-danger">
             Membresia
            </ModalHeader>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className=" pt-0 socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                    <label className='f-14 f-w-600 txt-danger' >Esta seguro de generarle una cortesia a este paciente</label>
                 <Media> <br /> <br /><Media className="img-100 img-fluid m-r-20 rounded-circle" src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                <Media body>
                                    <Row>
                                        <Col sm="6">
                                        <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="icofont icofont-user"> </i><span className="px-2">{"Usuario"}
                                        <Input
                                        disabled={loadingUpdateMethod ? true : false}
                                        type="text"
                                        name="user_method"
                                        value={user_method}
                                        required={true}
                                        autoComplete="nope"
                                        onChange={handleInputChangeMethod}
                                        className={`form-control form-control-sm input-air-primary ${(aunthentication ? 'border-danger' : 'border-primary')}`}
                                    />
                                    </span></span></span>
                                        </Col>
                                        <Col sm="6">
                                        <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="icofont icofont-key"> </i><span className="px-2">{"Contraseña"}
                                        <Input
                                            disabled={loadingUpdateMethod ? true : false}
                                            type="password"
                                            name="user_password_method"
                                            value={user_password_method}
                                            required={true}
                                            autoComplete="nope"
                                            onChange={handleInputChangeMethod}
                                            className={`form-control form-control-sm input-air-primary ${(aunthentication ? 'border-danger' : 'border-primary')}`}
                                        />
                                        </span></span></span>
                                        </Col>
                                    </Row>
                                  
   
                                </Media>
                        </Media>
                       
                </CardBody>   
                <ModalFooter>
                    <Col sm="12">
                    <Row>
                    <Col sm="8" style={{textAlign:"end"}}>
                    <Button  disabled={loadingUpdateMethod ? true : false} color='primary'  onClick={() => validatePasswordCortesy()}  >Aceptar</Button>
                    </Col>
                    <Col sm="4">
                    <Button  disabled={loadingUpdateMethod ? true : false} color='danger' onClick={toggleCourtesy}  >Cancelar</Button>
                    </Col>
                    </Row>
                    </Col>
                </ModalFooter>
                </ModalBody>
            </Form>
          
        </Modal>
    )
}
