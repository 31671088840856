import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Card, CardBody, Table, Button } from 'reactstrap';
export const ModalPreviewGlobalCash = (props) => {

    const {
        typeModal = "ViewGlobal", modal, toggle, loadingData, dataGlobal, formatter,printTicket

    } = props;



    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} size={loadingData === false ? '' : 'xl'} scrollable={true} >

            <ModalBody className="border-1 b-primary p-l-0 p-r-0 "  >
                {
                    typeModal === "ViewGlobal" ?
                        <div>
                            {
                                loadingData === false ?
                                    <>
                                        <Row className='mt-2'>
                                            <Col sm="12" md="12" lg="12" className='text-center'>
                                                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            <Col sm="3" md="3" lg="3" className='text-left'>
                                                <p className='f-24 text-primary'><b>Cargando</b></p>
                                            </Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'>
                                                <br />
                                                <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                            </Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'>
                                                <br />
                                                <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                            </Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'>
                                                <br />
                                                <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                            </Col>
                                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                        </Row>
                                        <Col sm="12" style={{ textAlign: "end" }} >
                                            <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggle}>Cerrar</Button>

                                        </Col>
                                    </>
                                    :
                                    <div>
                                            <hr />
                                            <div className="table-responsive">
                                                <Table className="">
                                                    <thead className="theadInvoice">
                                                        <tr>
                                                            <th className='labelThead'>Sucursal</th>
                                                            <th className='labelThead'>Encargado</th>
                                                            <th className='labelThead'>Fecha y Hora</th>
                                                            <th className='labelThead'>Tipo</th>
                                                            <th className='labelThead'>Monto Real</th>
                                                            <th className='labelThead'>Monto Capturado</th>
                                                            <th className='labelThead'>Observaciones</th>
                                                            <th className='labelThead'>Acciones</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            dataGlobal.length >= 1 && dataGlobal.map((_data, _key) => {
                                                                console.log("🚀 ~ dataGlobal.length>=1&&dataGlobal.map ~ _data:", _data);
                                
                                                                const _valid_Amount = parseFloat(_data.amount.replace(/[^0-9.-]+/g, "")); 
                                                                const _valid_capture_Amount = parseFloat(_data.captureAmount.replace(/[^0-9.-]+/g, "")); 
                                                                const _valid_amounts = _valid_Amount === _valid_capture_Amount;

                                                                return (
                                                                    <tr key={_key}>
                                                                        <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_data.branch}</td>
                                                                        <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_data.userCapture}</td>
                                                                        <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_data.fechCashFlow}</td>
                                                                        <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_data.pay_method}</td>
                                                                        <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_data.amount}</td>
                                                                        <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_data.captureAmount}</td>
                                                                        <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_data.comment}</td>
                                                                        <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>
                                                                            <Button outline color='success' size='xs' onClick={() => printTicket(_data.id_cash_flow)}>Descargar</Button>
                                                                        </td>
                                                                        <td className={_valid_amounts ? 'type-success' : 'type-cancell'}>{_valid_amounts ? <i className='fa fa-check txt-success f-20' ></i> : <i className='fa fa-times text-danger f-20' ></i> }</td>

                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                        <tr></tr>
                                                    </tbody>
                                                </Table>
                                            </div>


                                    </div>
                            }

                        </div>

                        :
                        ""

                }



            </ModalBody>
            {
                loadingData === false ? "" : <ModalFooter>
                    <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggle}>Cerrar</Button>
                </ModalFooter>


            }



        </Modal>
    )
}